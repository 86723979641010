<template>
      <div class="">
      <div class="d-flex mb-2">
          <span class="page-header-title">Notifications List</span>
    </div>
  <CCard>
    <CCardBody>
      <button
        color="primary"
        class="btn btn-primary float-right ml-3 mt-3"
        @click="openNewController()"
      >
        <em class="fa fa-plus" aria-hidden="true"></em> Add New
      </button>

      <div class="table-responsive">
        <CustomTableUser
          class="vertical-align-middle table-responsive account_table mt-3"
          :items="notificationList"
          hover
          striped
          border
          :small="'small'"
          fixed
          caption
          :fields="fields"
          :total="total"
          :per-page="perPage"
          :loadTableData="loadTableData"
          :rowSelectHandler="rowClickHandler"
          :onRowDeleteHandler="deleteTableData"
          :onRowEditHandler="editTableData"
          :rowFliterHandler="rowFliterHandler"
          :currentPagination="
            notificationFilters.start == 0 ? 1 : currentPageOfNotificationTable
          "
        />
      </div>

      <b-modal
        id="controllerType"
        title="New Notification"
        ok-title="Send"
        cancel-title="Cancel"
        size="lg"
      >
        <div v-if="roleID == 5">
          <label class="font-weight-bold">Region</label>
          <p class="notification-input">
            <b-form-select
              v-model="notificationsObj.region_name"
              :options="optionRegionManagers"
              class="mb-2"
            >
            </b-form-select>
            <show-errors
              class=""
              :control="$v.notificationsObj.region_name"
              :field="$t('app.template.management.tempalte.name')"
            />
          </p>
        </div>
        <div v-else-if="roleID == 4 || roleID == 8" class="notify-role">
          <div class="d-flex">
            <label class="font-weight-bold">Region </label>
            <p class="ml-3">{{ region_details.region_name }}</p>
          </div>
        </div>
        <label class="asterisk font-weight-bold">Title </label>
        <p class="notification-input">
          <CInput
            placeholder="Title"
            v-model="notificationsObj.title"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.notificationsObj.title"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Subtitle </label>
        <p class="notification-input">
          <CInput
            placeholder="Subtitle"
            v-model="notificationsObj.subject"
            required
            was-validated
          />
          <show-errors
            class=""
            :control="$v.notificationsObj.subject"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <label class="mt-2 asterisk font-weight-bold">Description </label>
        <p class="">
          <quill-editor
            v-model="notificationsObj.details"
            ref="notificationQuillEditor"
            :options="editorOption"
            @change="oneEditorChange($event)"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
            
          >
          </quill-editor>
          <show-errors
            class=""
            :control="$v.notificationsObj.details"
            :field="$t('app.template.management.tempalte.name')"
          />
        </p>
        <template v-slot:modal-footer="{}">
          <div class="col-lg-12 col-sm-12 col-12 p-0 row ml-0 mr-0">
            <div class="col-lg-12 col-12 col-sm-12 p-0 text-right">
              <b-button
                size="md"
                variant="secondary"
                class="ml-2 px-2"
                @click="$bvModal.hide('controllerType')"
                >Cancel
              </b-button>
              <b-button
                :disabled="clickedOnce == true"
                size="md"
                variant="primary"
                class="ml-2 px-2"
                @click="submitNotification()"
                >Create
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <b-modal
        hide-footer
        id="controllerTypeDetails"
        title="Notification details"
        ok-title="Send"
        cancel-title="Cancel"
        @ok="deleteAccountItem"
        size="lg"
      
      >
        <div class="row">
          <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Region</div>
          <div class="col-lg-10 mt-2 pt-1">
            {{ notificationsDetails.region_name }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Title</div>
          <div class="col-lg-10 mt-2 pt-1">
            {{ notificationsDetails.title }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Subtitle</div>
          <div class="col-lg-10 mt-2 pt-1">
            {{ notificationsDetails.subject }}
          </div>
        </div>
        <div class="row">
          <div class="col-lg-2 mt-2 pt-1 font-weight-bold">Description</div>

          <div class="col-lg-10 mt-2 pt-1">
            <div class="ql-snow" oncontextmenu="return false;" onmousedown="event.preventDefault ? event.preventDefault() : event.returnValue = false">
              <div
                class="content-manage ql-editor"
                draggable="false"
                v-html="notificationsDetails.details"
              ></div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        hide-header
        id="deletePopupUser"
        class="cancelbtn"
        title="Delete Data"
        ok-title="Yes"
        cancel-title="Cancel"
        @ok="deleteUsers"
      >
        <p class="my-4 wrap">
          Are you sure want to delete <b> {{ selectedNotification }}</b
          >?
        </p>
      </b-modal>
    </CCardBody>
  </CCard>
  </div>
</template>

<script>
import { multipleErrorMessages, senitize } from "@/shared/utils";
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  NOTIFICATION_LIST,
  NOTIFICATION_ADD,
  NOTIFICATION_DETAILS,
  DELETE_NOTIFICATIONS,
  MANAGER_REGION_LIST,
} from "@/store/actions.type";
import CustomTableUser from "@/components/Table/TableUser.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-paste-smart";
import { quillEditor, Quill } from "vue-quill-editor";
import ImageCompress from "quill-image-compress";
Quill.register("modules/imageCompress", ImageCompress);
var Block = Quill.import("blots/block");
var sizeof = require("object-sizeof");
Block.tagName = "div";
Quill.register(Block);
import ShowErrors from "@/components/ShowErrors/ShowErrors.vue";
import { required, maxLength } from "vuelidate/lib/validators";
export default {
  name: "NotificationDetails",
  components: { CustomTableUser, ShowErrors, quillEditor },
  data() {
    return {
      clickedOnce: false,
      optionRegionManagers: [{ value: "", text: "Select Region Name" }],
      editorOption: {
        modules: {
          clipboard: {
            allowed: {
              tags: [
                "a",
                "b",
                "strong",
                "u",
                "s",
                "i",
                "p",
                "br",
                "ul",
                "ol",
                "li",
                "span",
                "h1",
                "h2",
                "div",
                "h3",
                "h4",
                "h5",
              ],
              attributes: ["href", "rel", "target", "class","style"],
            },
            keepSelection: true,
            substituteBlockElements: true,
            magicPasteLinks: true,
            hooks: {
              uponSanitizeElement(node) {
                console.log(node);
              },
            },
          },
          imageCompress: {
            quality: 0.7, // default
            maxWidth: 1000, // default
            maxHeight: 1000, // default
            imageType: "image/jpeg", // default
            debug: true, // default
            suppressErrorLogging: false, // default
          },

          toolbar: [
            // ["link", "emoji", "image"],
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            // [{ header: 1 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme

            [{ align: [] }],
            ["image", "video", "link"],
            ["clean"],
          ],
        },
      },
      notificationsObj: {
        title: "",
        subject: "",
        details: "",
        region_name: "",
      },
      notificationsDetails: {
        title: "",
        subject: "",
        details: "",
        region_name: "",
      },
      currentPage: 1,
      pageStart: 0,
      perPage: 50,
      total: 0,
      sortField: "created_at",
      sortDirection: "desc",
      notificationList: [],
      del_account_name: "",
      customToolbar: [["bold", "italic", "underline"]],
      selectedNotification: "",
      notificationIds: "",

      fields: [
        {
          key: "title",
          sortable: true,
          label: "Title",
          _classes: "title-name",
        },
        {
          key: "subject",
          label: "Subtitle",
          sortable: true,
          _classes: "subtitle-name",
        },

        {
          key: "created_at",
          label: "Sent On",
          sortable: true,
          _classes: "sent-on",
        },
        {
          key: "deleteAction",
          label: "Action",
          sortable: false,
          _classes: "text-center",
        },
      ],
      notificationFilters: {
        start: 0,
       limit: 50,
        order_by: "desc",
        sort_by: "created_at",
      },
      currentPageOfNotificationTable: 1,
      nameOfRegion: "",
    };
  },
  //validation for the fields
  validations: {
    notificationsObj: {
      title: { required, maxLength: maxLength(100), senitize },
      subject: { required, maxLength: maxLength(100), senitize },
      details: { required },
      region_name: {},
    },
  },
  computed: {
    roleID() {
      return this.$store.state.auth.roleID;
    },
    regionName() {
      return this.$store.state.auth.regionName;
    },
    ...mapGetters(["region_details"]),
  },
  methods: {
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    oneEditorChange(e) {
      if (e.html == "<div><br></div>") {
        this.notificationsObj.details = "";
      } else {
        this.notificationsObj.details = e.html;
      }
    },
    changeColor() {
      document.documentElement.style.setProperty("--sidebarBgColor", "red");
    },

    /**
     * @method openNewController
     * @description Tt will pre fill the data on click of list data
     * @author @Jeevan Poojary
     */
    openNewController() {
      this.clickedOnce = false;
      this.notificationsObj.title = "";
      this.notificationsObj.subject = "";
      this.notificationsObj.details = "";
      this.notificationsObj.region_name = "";
      this.$v.notificationsObj.$reset();
      this.$bvModal.show("controllerType");
    },
    /**
     * @method UpdateForm
     * @description Tt will pre fill the data on click of list data
     * @author @Jeevan Poojary
     */
    async rowClickHandler(item) {
      this.isLoadingRow = this.$loading.show();
      if (item.id) {
        let data = await this.$store.dispatch(NOTIFICATION_DETAILS, {
          id: item.id,
        });
        this.notificationsDetails.title = data.title;
        this.notificationsDetails.subject = data.subject;
        this.notificationsDetails.details = data.details;
        this.notificationsDetails.region_name =
          data.region_name != null ? data.region_name : "All Regions";
        this.isLoadingRow.hide();
        this.$bvModal.show("controllerTypeDetails");
      }
      this.isLoadingRow.hide();
    },

    /**
     * @method fetchNotificationsList
     * @description Tt will fetch all notifications data
     * @author @Jeevan Poojary
     */

    async fetchNotificationsList() {
      this.notificationFilters.start =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.notificationFilters.limit = this.perPage;
      this.notificationFilters.sort_by = this.sortField;
      this.notificationFilters.order_by = this.sortDirection;
      this.isLoadingFetchNotification = this.$loading.show();
      let data = await this.$store.dispatch(NOTIFICATION_LIST, {
        filters: this.notificationFilters,
      });
      this.isLoadingFetchNotification.hide();
      if (_.get(data, "success", true)) {
        this.notificationList = _.get(data, "list", []);
        this.total = _.get(data, "total", 0);
      } else {
        let message = _.get(data, "message", "");
        this.notificationList = [];
        this.total = 0;
        if (message.length > 0) {
          this.$toast.error({
            message: message,
          });
        } else {
          this.$toast.removeAll();
          let messageObj = _.get(data, "message", "");
          multipleErrorMessages(this.$toast, messageObj);
        }
      }
    },

    /**
     * @method deleteUsers
     * @description Deleting notifications data from list
     * @author @Jeevan Poojary
     */
    async deleteUsers() {
      if (this.notificationIds) {
        let data = await this.$store.dispatch(DELETE_NOTIFICATIONS, {
          id: this.notificationIds,
        });
        if (_.get(data, "success", true)) {
          this.$toast.removeAll();
          this.$toast.success(_.get(data, "data.message", ""));
          this.notificationIds = "";
          this.selectedNotification = "";

          if (this.notificationList.length == 1) {
            this.pageStart = 0;
            this.currentPage = 1;
            this.perPage = 20;
          }

          this.fetchNotificationsList();
        } else {
          this.$toast.removeAll();
          let message = _.get(data, "data.message", "");
          multipleErrorMessages(this.$toast, message);
        }
      }
    },

    /**
     * @method deleteTableData
     * @description opens delete popup
     * @author @Jeevan Poojary
     */
    deleteTableData(item) {
      this.selectedNotification = item.title;
      this.notificationIds = item.id;
      this.$bvModal.show("deletePopupUser");
    },

    /**
     * @method loadTableData
     * @description Method to load table data
     * @author @Jeevan Poojary
     */
    async loadTableData(currentPage, limit) {
      this.currentPage = currentPage;
      this.pageStart =
        this.currentPage == 1 ? 0 : (this.currentPage - 1) * this.perPage;
      this.perPage = limit;
      this.currentPageOfNotificationTable = currentPage;
      this.fetchNotificationsList();
    },

    /**
     * @method rowFliterHandler
     * @description Method to load table data with filter
     * @author @Jeevan Poojary
     */
    async rowFliterHandler(e) {
      this.sortDirection = e.asc ? "asc" : "desc";
      this.sortField = e.column;
      this.fetchNotificationsList();
    },

    /**
     * @method submitNotification
     * @description Tt will save notification data
     * @author @Jeevan Poojary
     */
    async submitNotification() {
     
      if (sizeof(this.notificationsObj.details) > 1048576 * 5) {
        this.$toast.error({
          message: "Maximum size is exceeded.",
        });
        return;
      }
      if (this.roleID == 4 || this.roleID == 8) {
        this.notificationsObj.region_name = this.region_details.region_name;
      }
      this.$v.$touch();
      if (!this.$v.notificationsObj.$invalid) {
        this.clickedOnce = true;
        this.isLoadingSubmitNotify = this.$loading.show();
        await this.$store
          .dispatch(NOTIFICATION_ADD, this.notificationsObj)
          .then((data) => {
            if (_.get(data, "data.success", false)) {
              this.isLoadingSubmitNotify.hide();
              this.$toast.removeAll();
              this.$toast.success({
                message: _.get(data, "data.message", ""),
              });
              this.$bvModal.hide("controllerType");
              this.$v.notificationsObj.$reset();
              this.fetchNotificationsList();
            } else {
              this.$bvModal.hide("controllerType");
              this.isLoadingSubmitNotify.hide();
              let message = _.get(data, "message", "");
              if (message.length > 0) {
                this.$toast.error({
                  message: message,
                });
              } else {
                this.$toast.removeAll();
                let messageObj = _.get(data, "message", "");
                multipleErrorMessages(this.$toast, messageObj);
              }
            }
          });
      }
    },
  },
  async created() {
    let dropdownRole = await this.$store.dispatch(MANAGER_REGION_LIST);
    let roleOfOptions = [];
    let i;
    for (i = 0; i < dropdownRole.length; i++) {
      roleOfOptions.push({
        text: dropdownRole[i].name,
        value: dropdownRole[i].name,
      });
    }
    this.optionRegionManagers = _.concat(
      ...this.optionRegionManagers,
      roleOfOptions
    );
    this.fetchNotificationsList();
  },
};
</script>
<style>
.ql-editing {
  left: 1px !important;
}

.sent-on div {
  width: 150px !important;
}

.subtitle-name div {
  width: 300px !important;
}

.title-name div {
  width: 300px !important;
}

.content-manage {
  overflow: auto !important;
  padding: 0px !important;
  -webkit-overflow-scrolling: touch;
}

.content-manage::-webkit-scrollbar-thumb {
  background-color: #989898 !important;
}

.content-manage::-webkit-scrollbar {
  height: 5px !important;
  width: 5px !important;
}

.notification-input .form-group {
  margin-bottom: 0rem !important;
}

@media (max-width: 321px) {
  .notify-role .d-flex {
    display: flex !important;
  }
}

.ql-container .ql-editor {
  min-height: 225px !important;
}

.quill-text {
  min-height: 225px !important;
}

.ql-editor {
  min-height: 225px !important;
}

.wrap {
  word-break: break-word;
}
.img {
    /* For Opera and <= IE9, we need to add unselectable="on" attribute onto each element */
    /* Check this site for more details: http://help.dottoro.com/lhwdpnva.php */
    -moz-user-select: none; /* These user-select properties are inheritable, used to prevent text selection */
    -webkit-user-select: none;
    -ms-user-select: none; /* From IE10 only */
    user-select: none; /* Not valid CSS yet, as of July 2012 */

    -webkit-user-drag: none; /* Prevents dragging of images/divs etc */
   
}
</style>
